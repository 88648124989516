import { Modal as ReactModal } from "react-responsive-modal";
import { PaymentInputModal } from "@app/profile/subscription/PaymentInputModal";
import { Mode, UpgradeSection } from "@/types/util";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const UpgradeModal = ({
    show,
    setShow,
    message = "",
    children,
    setShowPaymentFeedbackModal,
}: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    message?: string;
    children: React.ReactNode;
    setShowPaymentFeedbackModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const [mode, setMode] = useState(Mode.Annually);
    const [showPaymentInputModal, setShowPaymentInputModal] = useState(false);
    const modes = [Mode.Annually, Mode.Monthly];

    useEffect(() => {
        if (show && !message) {
            setShow(false);
            setShowPaymentInputModal(true);
        }
    }, [message, show]);

    let heading = "";
    let body = "";

    switch (message) {
        case UpgradeSection.Close_Ad:
            (heading = "Trying to Close In-Feed Ad"),
                (body =
                    "Upgrade to Pro to enjoy an ad-free experience and uninterrupted access to information.");
            break;
        case UpgradeSection.Watchlist:
            (heading = "Creating a Watchlist of Assets"),
                (body =
                    "To create personalized watchlists, please upgrade to our Pro plan for advanced trading tools.");
            break;
        case UpgradeSection.Search:
            (heading = "Limitation on Historical Search for Free Users"),
                (body =
                    "Access to historical data is limited to the last one week. Upgrade to Pro for comprehensive historical insights.");
            break;
        case UpgradeSection.Filter_Creation:
            (heading = "Creating a Custom Filter Set"),
                (body =
                    "Custom filter sets are a feature of our Pro plan. Upgrade today to personalize your market analysis.");
            break;
        case UpgradeSection.Filter_Customization:
            (heading = "Toggling Specific Filters On or Off"),
                (body =
                    "Full customization of filters is available with our Pro plan. Upgrade now for enhanced control.");
            break;
        case UpgradeSection.Bookmark:
            (heading = "Saving Items in the Feed"),
                (body =
                    "Pro users can save articles and data for quick access. Upgrade to Pro to enhance your research capabilities.");
            break;
    }

    const onCloseModal = () => setShow(false);

    return (
        <>
            {show && message && (
                <ReactModal
                    open={show}
                    onClose={onCloseModal}
                    focusTrapped={false}
                    center
                    closeIcon={<XMarkIcon className="size-4 text-neutral" strokeWidth={2.5} />}
                    classNames={{
                        modal: "modal-bg-none w-100 min-w-100 modal-padding-0 modal-margin-0",
                    }}
                >
                    <div className="w-100 flex flex-col items-center justify-center gap-3 overflow-hidden rounded-lg border border-dark-blue bg-slate-blue p-0">
                        <div className="flex w-full flex-col items-center gap-6 px-4 pb-5 pt-4">
                            <h2 className="text-center text-lg text-white">{body}</h2>
                            <div
                                onClick={() => {
                                    setShow(false);
                                    setShowPaymentInputModal(true);
                                }}
                                className="cursor-pointer rounded-3xl border-none bg-powder-blue px-6 py-2 hover:brightness-75"
                            >
                                <p className="whitespace-normal text-sm font-normal text-white">
                                    Upgrade Now
                                </p>
                            </div>
                        </div>
                    </div>
                </ReactModal>
            )}

            <PaymentInputModal
                mode={mode}
                modes={modes}
                setMode={setMode}
                show={showPaymentInputModal}
                setShow={setShowPaymentInputModal}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
            />

            {children}
        </>
    );
};
