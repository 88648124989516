// import Modal from "@components/Modal";
import { Modal as ReactModal } from "react-responsive-modal";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useCreateSubQuery, useCreateSubscriptionMutation } from "@/services/stripe";
import { Mode } from "@/types/util";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "@app/profile/subscription/CheckoutForm";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/form-elements/Button";
import MemoLoading from "@assets/SVG/Loading";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);

export const PaymentInputModal = ({
    mode,
    show,
    modes,
    setMode,
    setShow,
    setShowPaymentFeedbackModal,
}: {
    mode: Mode;
    show: boolean;
    modes: Mode[];
    setMode: Dispatch<SetStateAction<Mode>>;
    setShow: Dispatch<SetStateAction<boolean>>;
    setShowPaymentFeedbackModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const { data } = useCreateSubQuery(mode);
    const [loadingNewSecret, setLoadingNewSecret] = useState(false);

    const { mutateAsync: createSubscription, isPending } = useCreateSubscriptionMutation();

    useEffect(() => {
        if (show) {
            if (!data?.clientSecret) {
                createSubscription(mode);
            }
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            if (!data?.clientSecret) {
                setLoadingNewSecret(true);
                createSubscription(mode).then(() => {
                    setLoadingNewSecret(false);
                });
            }
        }
    }, [mode]);

    const onCloseModal = () => setShow(false);
    if (!show) return <></>;

    return (
        <ReactModal
            open={show}
            onClose={onCloseModal}
            focusTrapped={false}
            center
            closeIcon={<XMarkIcon className="size-4 text-neutral" strokeWidth={2.5} />}
            classNames={{
                modal: "rounded-xl min-w-100 modal-padding-0 modal-margin-0 modal-bg-20242c",
            }}
        >
            {isPending || loadingNewSecret ? (
                <div className="relative flex h-44 items-center justify-center p-0">
                    <MemoLoading />
                </div>
            ) : data?.clientSecret ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret: data.clientSecret,
                    }}
                >
                    <CheckoutForm
                        subscriptionId={data.subscriptionId}
                        clientSecret={data.clientSecret}
                        mode={mode}
                        modes={modes}
                        setMode={setMode}
                        setShow={setShow}
                        loadingNewSecret={loadingNewSecret}
                        setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                    />
                </Elements>
            ) : (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setShow(false);
                    }}
                    className="flex w-100 flex-col items-center justify-center overflow-hidden rounded-lg bg-base-100 p-0"
                >
                    <div className="flex w-full flex-col items-center gap-2 px-4 pb-5 pt-4">
                        <div className="flex size-9 items-center justify-center bg-light-red/10">
                            <ExclamationTriangleIcon className="size-5 text-red-600" />
                        </div>
                        <div className="flex w-full flex-col items-center gap-2 px-0 pb-0 pt-2">
                            <h2 className="text-base text-white">Error Loading Payment Information</h2>
                            <p className="text-center text-xs font-light text-neutral">Please try again.</p>
                        </div>
                    </div>
                    <div className="flex items-start justify-center gap-2 px-4 pb-3 pt-0">
                        <Button
                            type="submit"
                            onClick={() => setShow(false)}
                            className="flex h-10 w-45 items-center justify-center gap-2.5 rounded-lg border-none bg-light-red px-2.5 py-3"
                        >
                            <p className="text-xs font-normal text-white">Close this</p>
                        </Button>
                    </div>
                </form>
            )}
        </ReactModal>
    );
};
