import { Mode } from "@/types/util";
import { Button } from "@components";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
import { Dispatch, SetStateAction, useState } from "react";
import MemoLoading from "@assets/SVG/Loading";
import { useCreateNewPaymentMutation } from "@services/payment";
import { useStytchUser } from "@stytch/nextjs";
import { getFullName } from "@services/user";
import {
    ClockIcon,
    BookmarkIcon,
    CurrencyDollarIcon,
    EyeIcon,
    MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import MemoInvertedFunnel from "@assets/SVG/InvertedFunnel";
import MemoAdFree from "@assets/SVG/AdFree";
import MemoCrown from "@assets/SVG/Crown";

export const CheckoutForm = ({
    subscriptionId,
    clientSecret,
    mode,
    modes,
    setMode,
    setShow,
    loadingNewSecret,
    setShowPaymentFeedbackModal,
}: {
    subscriptionId: string;
    clientSecret: string;
    mode: Mode;
    modes: Mode[];
    setMode: Dispatch<SetStateAction<Mode>>;
    setShow: Dispatch<SetStateAction<boolean>>;
    loadingNewSecret: boolean;
    setShowPaymentFeedbackModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useStytchUser();

    const fullName = getFullName(user?.name);

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");

    const { mutate: createNewPaymentEntry } = useCreateNewPaymentMutation();

    const isYearly = mode === Mode.Annually;

    const handleSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        try {
            const cardNumberElement = elements.getElement(CardNumberElement);

            if (cardNumberElement) {
                const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardNumberElement,
                        billing_details: {
                            email: user?.emails[0].email,
                            name: fullName,
                            // Add billing details if needed
                        },
                    },
                });

                if (error) {
                    const showError = error.type === "card_error" || error.type === "validation_error";

                    if (showError) {
                        setMessage(error.message as string);
                    }
                } else {
                    // Payment succeeded
                    const paymentId = paymentIntent.id;
                    setMessage("");
                    createNewPaymentEntry({
                        payment_id: paymentId,
                        subscription_type: mode,
                        subscriptionId,
                    });

                    setShow(false);
                    setShowPaymentFeedbackModal(true);
                }
            } else {
                // Handle the case where cardNumberElement is null
                setMessage("Card number element is null. Please check your form.");
            }
        } catch (error) {
            console.error(error);
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const NUMBER_ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
        showIcon: true, // Enable card icons
        iconStyle: "solid",
        placeholder: "•••• •••• •••• ••••",
        style: {
            base: {
                color: "#A1ADC1",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#9e2146",
            },
        },
    };

    const EXPIRY_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#A1ADC1",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#9e2146",
            },
        },
    };

    const CVC_ELEMENT_OPTIONS = {
        placeholder: "Enter CVV code",
        style: {
            base: {
                color: "#A1ADC1",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#9e2146",
            },
        },
    };

    return (
        <div className="flex rounded-lg bg-base-100 py-2.5">
            <div className="flex w-100 flex-col items-center justify-center gap-0.5 overflow-hidden px-4 py-2.5 text-xs">
                <div className="flex h-20 w-20 items-center justify-center">
                    <MemoCrown className="h-16 w-[72px]" fill="#306CE8" />
                </div>
                <div className="flex w-full flex-col items-center px-0 pb-3 pt-2">
                    <h2 className="text-base font-normal leading-5 text-white">Pro Plan</h2>
                    <p className="w-[210px] text-center text-xs font-light text-neutral">
                        A Suite of Advanced Features Tailored for the Discerning User
                    </p>
                </div>
                <div className="flex w-full flex-col items-center gap-1 rounded-lg bg-slate-blue px-0 py-2">
                    <div className="w-full gap-2 px-3 py-1">
                        <div className="flex w-full gap-3">
                            <div className="flex size-5 items-center justify-center rounded-[4px] bg-dark-blue">
                                <ClockIcon className="size-2.5 text-neutral" />
                            </div>
                            <div className="flex w-full flex-col gap-0.5 border-b border-royal-blue px-0 pb-2 pt-0">
                                <p className="text-sm font-normal leading-4 text-white">
                                    Instant Real-Time News
                                </p>
                                <p className="text-xs font-light text-neutral">
                                    Immediate updates from thousands of sources using advanced Context Hub
                                    technology.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full gap-2 px-3 py-1">
                        <div className="flex w-full gap-3">
                            <div className="flex size-5 items-center justify-center rounded-[4px] bg-dark-blue">
                                <CurrencyDollarIcon className="size-2.5 text-neutral" />
                            </div>
                            <div className="flex w-full flex-col gap-0.5 border-b border-royal-blue px-0 pb-2 pt-0">
                                <p className="text-sm font-normal leading-4 text-white">
                                    Live Dynamic Price Screener
                                </p>
                                <p className="text-xs font-light text-neutral">
                                    Real-time pricing from top exchanges with live sorting features.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full gap-2 px-3 py-1">
                        <div className="flex w-full gap-3">
                            <div className="flex size-5 items-center justify-center rounded-[4px] bg-dark-blue">
                                <MagnifyingGlassIcon className="size-2.5 text-neutral" />
                            </div>
                            <div className="flex w-full flex-col gap-0.5 border-b border-royal-blue px-0 pb-2 pt-0">
                                <p className="text-sm font-normal leading-4 text-white">
                                    Unlimited Search History
                                </p>
                                <p className="text-xs font-light text-neutral">
                                    Search more, discover more. Upgrade to Pro for unlimited historical data
                                    access.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full gap-2 px-3 py-1">
                        <div className="flex w-full gap-3">
                            <div className="flex size-5 items-center justify-center rounded-[4px] bg-dark-blue">
                                <MemoInvertedFunnel className="size-2.5 text-neutral" />
                            </div>
                            <div className="flex w-full flex-col gap-0.5 border-b border-royal-blue px-0 pb-2 pt-0">
                                <p className="text-sm font-normal leading-4 text-white">Custom Filter Sets</p>
                                <p className="text-xs font-light text-neutral">
                                    Get tailored news feeds! Upgrade to customize your news sources fully.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full gap-2 px-3 py-1">
                        <div className="flex w-full gap-3">
                            <div className="flex size-5 items-center justify-center rounded-[4px] bg-dark-blue">
                                <EyeIcon className="size-2.5 text-neutral" />
                            </div>
                            <div className="flex w-full flex-col gap-0.5 border-b border-royal-blue px-0 pb-2 pt-0">
                                <p className="text-sm font-normal leading-4 text-white">
                                    Personalized Watchlists
                                </p>
                                <p className="text-xs font-light text-neutral">
                                    Create detailed watchlists with Pro. Upgrade now for personalized asset
                                    tracking.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full gap-2 px-3 py-1">
                        <div className="flex w-full gap-3">
                            <div className="flex size-5 items-center justify-center rounded-[4px] bg-dark-blue">
                                <BookmarkIcon className="size-2.5 text-neutral" />
                            </div>
                            <div className="flex w-full flex-col gap-0.5 px-0 pb-2 pt-0">
                                <p className="text-sm font-normal leading-4 text-white">Save News Articles</p>
                                <p className="text-xs font-light text-neutral">
                                    Want to save articles for later? Upgrade to Pro to bookmark and revisit
                                    anytime.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full gap-2 px-3 py-1">
                        <div className="flex w-full gap-3">
                            <div className="flex size-5 items-center justify-center rounded-[4px] bg-dark-blue">
                                <MemoAdFree className="size-2.5" />
                            </div>
                            <div className="flex w-full flex-col gap-0.5 px-0 pb-2 pt-0">
                                <p className="text-sm font-normal leading-4 text-white">
                                    An Ad-Free Experience
                                </p>
                                <p className="text-xs font-light text-neutral">
                                    Experience our platform without interruptions. Go Pro for an ad-free
                                    environment.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-100 flex-col items-center justify-center overflow-hidden border-l border-royal-blue px-4 py-0 text-xs">
                <div className="flex w-full flex-col items-center gap-6 py-3">
                    <p className="text-xs font-light text-neutral">
                        With an annual subscription, you will <span className="text-secondary">save 20%</span>
                    </p>
                    <div className="flex w-[fit-content] items-start rounded-3xl bg-royal-blue p-1">
                        {modes.map((item, i) => (
                            <div
                                key={i}
                                onClick={() => setMode(item)}
                                className={`flex cursor-pointer items-center justify-center gap-2.5 rounded-3xl px-4.5 py-2.5 ${
                                    mode === item && "bg-powder-blue"
                                }`}
                            >
                                <p
                                    className={`text-xs font-normal ${
                                        mode === item ? "text-white" : "text-neutral"
                                    }`}
                                >
                                    {item}
                                </p>
                            </div>
                        ))}
                    </div>
                    <p className="flex items-center gap-1 text-4xl font-bold leading-[43.57px] text-white">
                        {isYearly ? "$16" : "$20"}
                        <span className="text-base font-normal leading-5">/month</span>
                    </p>
                </div>
                <div className="flex w-full justify-center gap-0.5 border-t border-royal-blue pb-3 pt-6">
                    <p className="text-base font-normal leading-5 text-white">Upgrade Now: Secure Payment</p>
                </div>

                <form onSubmit={handleSubmit} className="flex w-full flex-col gap-4">
                    <div className="flex w-full flex-col items-start justify-center gap-3 py-0">
                        <div className="mt-2 flex w-full flex-col items-start gap-1 p-0">
                            <label htmlFor="cardNumber" className="text-xs font-light text-neutral">
                                Card Number
                            </label>
                            <CardNumberElement
                                id="cardNumber"
                                options={NUMBER_ELEMENT_OPTIONS}
                                className="h-10 w-full items-center rounded-lg border border-solid border-dark-blue p-3 text-xs font-light text-white"
                            />
                        </div>
                        <div className="flex w-full items-start gap-3 px-0 pb-2 pt-0">
                            <div className="flex w-1/2 flex-col items-start gap-1 p-0">
                                <label htmlFor="expiry" className="text-xs font-light text-neutral">
                                    Exp Date
                                </label>
                                <CardExpiryElement
                                    id="expiry"
                                    options={EXPIRY_ELEMENT_OPTIONS}
                                    className="h-10 w-full items-center rounded-lg border border-solid border-dark-blue p-3 text-xs font-light text-white"
                                />
                            </div>
                            <div className="flex w-1/2 flex-col items-start gap-1 p-0">
                                <label htmlFor="cvc" className="text-xs font-light text-neutral">
                                    CVC
                                </label>
                                <CardCvcElement
                                    id="cvc"
                                    options={CVC_ELEMENT_OPTIONS}
                                    className="h-10 w-full items-center rounded-lg border border-solid border-dark-blue p-3 text-xs font-light text-white"
                                />
                            </div>
                        </div>
                    </div>

                    <Button
                        onClick={handleSubmit}
                        type="submit"
                        // id="submit"
                        disabled={!stripe || !elements || loadingNewSecret}
                        className="h-10 w-full rounded-lg border-none bg-powder-blue"
                    >
                        <p className="text-xs font-normal text-white">
                            {isLoading ? <MemoLoading className="w-4" /> : "Upgrade Now"}
                        </p>
                    </Button>
                </form>

                {message && (
                    <div className="mt-4" id="payment-message">
                        {message}
                    </div>
                )}

                <div className="flex w-full items-start justify-start gap-2 py-3">
                    <p className="text-[10px] leading-4 text-neutral">
                        This plan is billed at
                        {isYearly ? " $16 " : " $20 "}
                        per month
                        {isYearly && ", charged annually"}
                    </p>
                </div>
            </div>
        </div>
    );
};
