import { Modal as ReactModal } from "react-responsive-modal";
import { Button } from "@/components";
import MemoCrown from "@assets/SVG/Crown";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useRouter } from "next/navigation";

export const PaymentFeedbackModal = ({
    show,
    setShow,
}: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) => {
    const router = useRouter();

    useEffect(() => {
        if (show) {
            setTimeout(() => router.push("/profile/subscription"), 3000);
        }
    }, [show]);

    const onCloseModal = () => setShow(false);

    return (
        <ReactModal
            open={show}
            onClose={onCloseModal}
            focusTrapped={false}
            center
            showCloseIcon={false}
            classNames={{
                modal: "modal-bg-none min-w-100 modal-radius-5 modal-padding-0 modal-margin-0",
            }}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    setShow(false);
                }}
                className="w-100 flex flex-col items-center justify-center overflow-hidden rounded-lg bg-base-100 p-0"
            >
                <div className="flex w-full flex-col items-center gap-1 px-4 pb-5 pt-4">
                    <div className="flex size-9 items-center justify-center bg-dark-blue">
                        <MemoCrown className="size-5" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">Welcome to Pro!</h2>
                        <p className="text-center text-xs font-light text-neutral">
                            Congratulations on purchasing the pro plan.
                        </p>
                    </div>
                </div>
                <div className="flex w-full items-start justify-center gap-2 px-4 pb-3 pt-0">
                    <Button
                        type="submit"
                        onClick={() => setShow(false)}
                        className="flex h-10 w-full items-center justify-center gap-2.5 rounded-lg border-none bg-powder-blue px-2.5 py-3"
                    >
                        <p className="text-xs font-normal text-white">Okay, close this</p>
                    </Button>
                </div>
            </form>
        </ReactModal>
    );
};
